<template>
  <div class="service-times-presentations template-2">
    <page-header class="mx-lg">
      <h1>{{ translations.tcVideoPresentations }}</h1>
      <BackJump @reload_host="handleFacilityChange()" :obj="backJumpObj" :vis="true" :i18n="translations.components">
      </BackJump>
    </page-header>

    <page-body>
      <section class="section-2">
        <div class="bg-block p-4">
          <header class="mb-5">
            <h2>
              {{ translations.tcPresentations }}
              <span class="i-tooltip" v-b-tooltip.hover :title="translations.tcTooltip">
                <i-tooltip />
              </span>
            </h2>
            <ToggleArrow v-if="toggle == true" @click="Section2 = !Section2" section="Section2" :status="Section2">
            </ToggleArrow>
          </header>
          <b-button
            v-if="Section2 && iCanSee(secured_gideoncard_visit_add_controls.add_gideoncard_video_presentation_button)"
            variant="primary" class="mr-2 mb-3" @click="handleAddPresentationClick">{{ translations.tcAddVideoPresentation
            }}</b-button>
          <div v-if="Section2 && translations.components" class="row d-flex events">
            <div class="col col-4 mt-2" v-for="(pres, index) in presentations" :key="index" :obj="pres">
              <app-event :key="index" :obj="pres" :i18n="translations.components"></app-event>
            </div>
            <p v-if="presentations.length === 0">{{ translations.tcNoPresentationsFound }}</p>
          </div>
        </div>
      </section>
    </page-body>

    <page-footer class="mx-lg">
      <BackJump :obj="backJumpObj" :vis="false" :i18n="translations.components"></BackJump>
    </page-footer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import AddServiceTime from '@/components/AddServiceTime.vue'
import BackJump from '@/components/back-jump/BackJumpGideonCards.vue'
import dateData from '@/json/date.json'
import Event from '@/components/Event.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import { numericMixin } from '@/mixins/numericMixin'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import Service from '@/components/date-card/Service.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import { translationMixin } from '@/mixins/translationMixin'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
  name: 'card-video-visits-list',
  mixins: [numericMixin, translationMixin],
  data() {
    return {
      translations: {},
      secured_gideoncard_visit_add_controls: {
        add_gideoncard_video_presentation_button: 'ed32320b-3333-444d-9f80-038c2808c769',
      },
      service_to_edit: null,
      toggle: true,
      toggleMe: true,
      Section2: true,
      presentations: [],
      backJumpObj: {
        link: '#',
        location: '',
      },
    }
  },
  methods: {
    ...mapActions({
      clearSinglePresentation: 'churchMinistry/clearSinglePresentation',
      clearVideoVisitDetails: 'card/clearVideoVisitDetails',
      deleteChurchServiceTime: 'churchMinistry/deleteChurchServiceTime',
      getFacilityVideoVisits: 'card/getFacilityVideoVisits',
      loadChurchDetail: 'churchMinistry/loadChurchDetail',
      loadProfile: 'card/getProfile',
      setLoadingStatus: 'menu/setLoadingStatus',
      setPresentationEditKey: 'churchMinistry/setPresentationEditKey',
      setSelectedFacilityKey: 'user/setSelectedFacilityKey',
      setSelectedVisitKey: 'churchMinistry/setSelectedVisitKey',
    }),
    updateCamp(value) {
      this.camp = value
    },
    async handleTimeAddSuccess() {
      await this.pageLoad()
    },
    async handleAddPresentationClick() {
      this.setSelectedVisitKey(null)
      this.clearVideoVisitDetails()
      this.$router.push('/programs/gc/events/card_video_presentation_add')
    },
    async handleServiceEditRequest(cti_key) {
      this.service_to_edit = await this.churchDetail.services.find((se) => se.cti_key === cti_key)
      await this.showModal(null, 'newServiceModal')
    },
    async handleServiceDeleteRequest(cti_key) {
      let result = true
      this.setLoadingStatus(true)
      await Promise.all([(result = await this.deleteChurchServiceTime(cti_key)), await this.pageLoad()]).then(
        (result) => {
          this.$swal({
            icon: result ? 'success' : 'error',
            showCancelButton: false,
            text: result ? this.translations.tcDeleteSuccessful : this.translations.tcDeleteFailed
          })
        }
      )
    },
    async handleViewAllPresentationsClick() {
      if (this.selectedChurchKey !== null && this.isGuid(this.selectedChurchKey)) {
        this.$router.push('/programs/cm/presentations/1')
      }
    },
    async handleFacilityChange() {
      await this.loadProfile(this.userSelectedFacilityKey).then(() => {
        this.pageLoad()
      })
    },
    async pageLoad() {
      await Promise.all([this.setLoadingStatus(true), this.getFacilityVideoVisits(this.userSelectedFacilityKey)]).then(
        () => {
          this.backJumpObj.location = this.profileSummary.org_name
          this.backJumpObj.link = `/programs/gc/profile/summary/card_profile`
          this.setLoadingStatus(false)
          this.presentations = this.list.map((visit) => {
            let today = new Date()
            let activityDate = new Date(visit.presentation_date)
            let monthName = dateData.months.find((mi) => parseInt(mi.value) === activityDate.getMonth() + 1).abbr
            return {
              presenter: `${this.translations.tcPresenter} ${visit.presenter_name}`,
              status: visit.status,
              key: visit.mtg_key,
              month: monthName,
              link: `/programs/gc/profile/video-visit`,
              day: activityDate.getDate(),
              year: activityDate.getFullYear(),
              fullDate: visit.presentation_date,
            }
          })
        }
      )
    },
    showModal(e, modalName) {
      this.$root.$emit('bv::show::modal', `${modalName}`)
    },
  },
  async created() {
    await Promise.all([
      this.getViewTranslations(),
      this.getComponentTranslations('back-jump-gideon-cards', 'quick-jump-gideon-cards', 'event'),
    ]).then((results) => {
      const translatedText = { ...results[1] }
      this.$set(this.translations, 'components', translatedText)
      this.pageLoad()
    })
  },
  computed: {
    ...mapGetters({
      churchDetail: 'churchMinistry/churchDetail',
      iCanSee: 'user/iCanSee',
      list: 'card/getFacilityVideoList',
      prefCulture: 'user/userPreferredCulture',
      profileSummary: 'card/getFacilitySummary',
      selectedChurchKey: 'user/userSelectedChurchKey',
      userSelectedFacilityKey: 'user/userSelectedFacilityKey',
    }),
  },
  components: {
    appEvent: Event,
    BackJump: BackJump,
    AddServiceTime: AddServiceTime,
    pageHeader: pageHeader,
    pageBody: pageBody,
    pageFooter: pageFooter,
    Service: Service,
    iTooltip: iTooltip,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';

.service-times-presentations {
  header h1 {
    padding-bottom: 80px;

    @include breakpoint(sm) {
      padding-bottom: 0;
    }
  }

  h2 {
    margin: 0;
    color: #000000;
    font-family: $DIN;
    font-size: 42px;
    letter-spacing: 1.4px;
    line-height: 44px;
    text-transform: uppercase;
    display: flex;

    .i-tooltip {
      position: relative;
      top: -5px;
      margin-left: 10px;
      line-height: 0;

      svg path {
        fill: #000;
      }
    }
  }

  section {
    margin-bottom: 40px;

    .p-4 {
      padding: 40px 30px 20px 30px !important;

      @include breakpoint(sm) {
        padding: 20px 24px 20px 24px !important;
      }
    }

    header {
      display: flex;

      h2 {
        flex: 1 1 auto;

        @include breakpoint(sm) {
          margin-bottom: 30px;
        }
      }

      .toggle-arrow {
        align-self: flex-start;
        margin-top: 5px;
      }
    }

    @include breakpoint(sm) {
      .btn {
        width: 100%;
        margin-bottom: 20px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  .service-times,
  .pres-dates {
    margin-top: 35px;
  }

  .pres-dates {
    .date-card {
      width: calc(33.333% - 15px);
      margin-right: 15px;

      @include breakpoint(sm) {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}

.vdp-datepicker div input,
.vue__time-picker input {
  border: 1px solid #636363;
  padding: 10px 20px !important;
  width: 18em;
  font-weight: 600;
  text-transform: uppercase;
}

.vdp-datepicker__calendar {
  font-weight: 600;
  text-transform: uppercase;
}

.vue__time-picker input.display-time {
  padding: 10px 20px !important;
  border: 1px solid #636363;
  width: 17em;
  font-weight: 600;
  height: 3.2em;
}

.events {
  margin-bottom: 50px;

  .col-4 {
    &:nth-of-type(even) {
      .event .date {
        background-image: linear-gradient(135deg, #0098c7 0%, #92cfeb 100%);
        background-image: linear-gradient(135deg, hsl(194, 100%, 39%) 0%, hsl(198, 68%, 75%) 100%);
      }
    }

    @include breakpoint(sm) {
      max-width: 100%;
      width: 100%;
      flex: 0 0 auto;

      &:last-of-type .event {
        margin-bottom: 0;
      }
    }
  }

  .event {
    margin-bottom: 0;
    border: 1px solid #e9e9e9;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);

    h3 {
      color: $teal-800;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 22px;
    }
  }
}
</style>
