<template>
  <div class="content date-card-service-cont">
    <div class="text">
      <p v-if="obj.speaker === 'No speaker assigned'">{{ i18n['service-date-card'].tcNoSpeakerAssigned }}</p>
      <p v-else>{{ charLimit(obj.speaker, 25) }}</p>
      <p v-if="obj.offering">{{ offeringText }}</p>
      <p v-if="obj.length">{{ obj.length }}</p>
      <p class="more-comp-line" v-if="obj.link ||  obj.status">
        <a v-if="obj.link" :href="obj.link">{{ i18n['service-date-card'].tcMore }}</a>
        <a v-if="linkOut" href="#" @click.prevent="handleLinkOut">{{ i18n['service-date-card'].tcMore }}</a>
        <span class="service-status">{{ status }}</span>
      </p>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
//(Service Times / Presentations) - Speaker, status, length, offering, link
export default {
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'service-date-card': {
            tcCompleted: 'Completed',
            tcIncomplete: 'Incomplete',
            tcMore: 'More',
            tcOffering: 'Offering',
            tcPending: 'Pending',
          },
        }
      },
    },
    linkOut: {
      type: Boolean,
      default: false,
    },
    obj: Object,
  },
  computed: {
    offeringText() {
      if (!this.obj.offering || this.obj.offering.length < 1) return ''
      return this.i18n['service-date-card'].tcOffering + ":" + this.obj.offering.split(':')[1]
    },
    status() {
      let statusProps = this.obj.status || ''
      if (statusProps === 'Completed') {
        return this.i18n['service-date-card'].tcCompleted
      } else if (statusProps === 'Incomplete') {
        return this.i18n['service-date-card'].tcIncomplete
      } else if (statusProps === 'Pending') {
        return this.i18n['service-date-card'].tcPending
      }
      return statusProps
    },
  },
  methods: {
    charLimit(text, limit) {
      if (text !== null) {
        return text.length > limit ? text.substring(0, limit) + '...' : text
      }
    },
    handleLinkOut() {
      this.$emit('link_out', this.obj)
    },
  }
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
//@import '@/styles/components/component-event.scss';
.date-card-service-cont {
  p {
    margin-bottom: 0;
    font-size: 15px;
    @include breakpoint(sm) {
      font-size: 18px;
    }
    a {
      font-size: 14px;
      color: #003946;
      font-weight: bold;
      text-transform: uppercase;
      @include breakpoint(sm) {
        font-size: 18px;
      }
    }
    &.more-comp-line {
      display: flex;
      margin-top: 4px;
      .service-status {
        margin-left: 15px;
        flex: 1 1 auto;
        text-align: right;
      }
    }
  }
}
</style>
